<template>
  <div class="side-menu" v-if="show">
    <div class="modal">
    </div>
    <div class="category">
      <div class="title">
        <p style="height:70px!important;">
          {{ title }}
        </p>
      </div>
      <div class="plist">
        <!-- <platform-live icon="/assets/images/hd.png" link="https://www.zhibo8.cc/" title="高清直播" /> -->
        <platform-live icon="/assets/logos/live/1.jpg" link="https://www.zhibo8.cc/" title="直播8" />
        <platform-live icon="/assets/logos/live/2.png" link="https://sports.qq.com/kbsweb/" title="腾讯体育" />
        <platform-live icon="/assets/logos/live/3.png"
          link="https://www.miguvideo.com/mgs/website/prd/sportMatchDetail.html" title="咪咕体育" />
        <platform-live icon="/assets/logos/live/4.png" link="https://tv.cctv.com/" title="CCTV5" />
        <platform-live icon="/assets/logos/live/5.jpg" link="https://live.qq.com/" title="企鹅直播" />
        <platform-live icon="/assets/logos/live/6.jpg" link="https://sports.youku.com/" title="优酷体育" />
        <platform-live icon="/assets/logos/live/7.jpg" link="https://www.ppsport.com/" title="PP体育" />
        <platform-live icon="/assets/logos/live/8.jpg" link="https://www.huya.com/" title="虎牙直播" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import PlatformLive from "@/component/PlatformLive.vue";

const props = defineProps({
  show: Boolean,
  title: String,
});

const type = ref("3");
const route = useRoute();

if (route.params.id != undefined) {
  const types = route.params.id.split("-");
  type.value = types[1];
}

const _side = ref(false);
</script>
<style scoped>
.menus {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.menus p {
  display: flex;
  width: 14%;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  color: var(--666, #666);
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menu-sel {
  text-align: center;
  font-size: 18px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #1890ff !important;
}

.menus img {
  width: 20px;
  height: 20px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
}

.side-menu .modal {
  z-index: 999;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: #000;
  position: fixed;
  bottom: 0px;
}

.side-menu .category {
  display: flex;
  flex-direction: column;
  height: 50%;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0px;
  background-color: white;
}

.title {
  display: flex;
  width: 100%;
  color: #333;
  text-align: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 70px;
  border-bottom: 0.5px solid #ccc;
  justify-content: space-between;
}

.title p {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  margin: auto;
}

.title .close {
  position: absolute;
  top: 22px;
  right: 20px;
  font-size: 20px;
}

.tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  color: var(--333, #333);
  text-align: center;
  font-size: 12px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.tag a {
  display: flex;
  width: 68px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #f5f5f5;
  margin: 10px auto;
}

.tag .sel-category {
  display: flex;
  width: 68px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--unnamed, #e84b5b);
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--unnamed, #e84b5b);
  background: rgba(232, 75, 91, 0.05);
}
</style>
